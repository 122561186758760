import { useAuth } from '../../hooks/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useToast from '../../hooks/useToast';

export default function useHome() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateToast } = useToast();

  const handleDecision = ({ Goto, disabled }) => {
    if (disabled) {
      updateToast({ text: t('feedbackSection.only') });
      return;
    }
    navigate(Goto);
    return;
  };

  return {
    user,
    t,
    handleDecision,
  };
}
