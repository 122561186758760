import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import './Button.scss';
import Tooltip from '../Tooltip/Tooltip';

const Button = ({
  label,
  iconName,
  iconColor = '#3d4752',
  iconSize = 24,
  handleClick,
  type = 'button',
  caption = '',
  className = '',
  disable = false,
  active = false,
  primary = false,
  secondary = false,
  gray = false,
  selected = true,
  full = false,
  name = '',
}) => {
  const buttonClass = classNames('button', className, {
    active,
    selected,
    full,
    primary,
    secondary,
    gray,
  });

  const button = () => (
    <button name={name} className={buttonClass} disabled={disable} type={type} onClick={handleClick}>
      {iconName && <Icon name={iconName} color={iconColor} size={iconSize} />}
      {label && <span>{label}</span>}
    </button>
  );

  return !caption ? button() : <Tooltip children={button()} content={<div>{caption}</div>} />;
};

export default Button;
