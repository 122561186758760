import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OrderService from '../../services/Order.service';
import useToast from '../../hooks/useToast';

export default function useSearch() {
  const { t } = useTranslation();
  const [inputChange, setInputChange] = useState('');
  const [order, setOrder] = useState(null);
  const { updateToast } = useToast();

  const handleInputChange = (e) => {
    setInputChange(e.target.value);
  };

  const handleSearch = async () => {
    try {
      setOrder(null);
      const result = await OrderService.getById({ id: inputChange });
      if (result) setOrder(result);
    } catch (error) {
      console.log(error);
      updateToast({ text: t('order.notFound') });
    }
  };

  return { t, inputChange, handleSearch, handleInputChange, order };
}
