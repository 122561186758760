import Button from '../../components/Button/Button';
import Flex from '../../components/Flex/Flex';
import useSearch from './useSearch';
import { Link } from 'react-router-dom';
import OrderDetails from './Components/OrderDetails';
import './Search.scss';

export default function Search() {
  const { t, inputChange, handleSearch, handleInputChange, order } = useSearch();

  return (
    <Flex className="orders" column>
      <Flex gap={'2px'} column className="form">
        <Flex alignCenter spaceBtw>
          <Link to="/">
            <Button iconName={'Home'} iconColor="#FFF" />
          </Link>
        </Flex>
      </Flex>
      <Flex column className="form none">
        <label>{t('find')}</label>
        <Flex>
          <input type="number" value={inputChange} onChange={handleInputChange} />
          <Button disable={!inputChange.length} label={t('search')} primary handleClick={handleSearch} />
        </Flex>
        <div className="s-order">{order && <OrderDetails t={t} order={order} />}</div>
      </Flex>
    </Flex>
  );
}
