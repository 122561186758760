import axios from './Client';

const get = async () => {
  const response = await axios.get(`/cooktypes`);
  return response.data;
};

const put = async ({ id, data }) => {
  const response = await axios.put(`/cooktypes/${id}`, data);
  return response.data;
};

const del = async ({ id }) => {
  const response = await axios.delete(`/cooktypes/${id}`);
  return response.data;
};

const post = async ({ data }) => {
  const response = await axios.post(`/cooktypes`, data);
  return response.data;
};

export default { get, put, post, del };
