import axios from './Client';

const get = async () => {
  const response = await axios.get(`/donation`);
  return response.data;
};

const create = async ({ data }) => {
  const response = await axios.post(`/donation`, data);
  return response.data;
};

export default { get, create };
