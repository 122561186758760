export const pt = {
  login: {
    signIn: 'Sign in',
    signUp: 'Sign up',
    speech: 'Bem-vindo à nova experiência do Jonnhys Burgers ',
    email: 'E-mail',
    password: 'Password',
    or: 'or',
    here: 'here',
  },
  a: 'a',
  cancel: 'Cancela',
  ok: 'Pronto',
  burgers: 'Burgers',
  combos: 'Combos',
  next: 'Próximo',
  drinks: 'Bebidas',
  cancel: 'Cancelar',
  back: 'Voltar',
  end: 'Finalizar',
  close: 'Fechar pedido',
  payment: 'Forma de pagamento',
  totalOrder: 'Total pedido',
  money: 'Dinheiro',
  welcome: 'Bem-vindo',
  orders: 'Pedidos',
  find: 'Pesquisar pedido',
  search: 'Buscar',
  exit: 'Sair',
  additionals: 'Adicionais',
  exclude: 'Excluir',
  change: 'Troco',
};
