const TENANT = process.env.REACT_APP_TENANT || 'brain';
export const getToken = () => {
  const token = localStorage.getItem(TENANT);
  return token ? JSON.parse(token).token : '';
};

export const getRefresh = () => {
  const token = localStorage.getItem(TENANT);
  return token ? JSON.parse(token).refresh : '';
};
