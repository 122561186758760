import { Link } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Flex from '../../components/Flex/Flex';
import './Login.scss';
import useLogin from './useLogin';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

export default function Login() {
  const {
    handleSubmit,
    username,
    password,
    handleChangeUser,
    handleChangePass,
    handleGoogle,
    clientId,
    disable,
    t,
    brand,
  } = useLogin();

  return (
    <Flex column className="login">
      <Flex className="logo" column justifyCenter alignCenter>
        <img src={`/${brand}/assets/logo.png`} />
        <small className="sp">{t(`brand.${brand}.speech`)}</small>
      </Flex>

      <Flex className="item sign">
        <form onSubmit={handleSubmit}>
          <Flex className="data" gap={'10px'} column={true}>
            <div>
              <h2>{t('login.signIn')}</h2>
              <small>{t(`brand.${brand}.loginSpeech`)}</small>
            </div>
            <div>
              <label>{t('login.email')}</label>
              <input type="email" required value={username} onChange={handleChangeUser} />
            </div>
            <div>
              <label>{t('login.password')}</label>
              <input type="password" required value={password} onChange={handleChangePass} />
            </div>
            <Button primary disable={disable} type="submit" label={t('login.init')} />

            <label className="or">- {t('login.or')} -</label>
            <GoogleOAuthProvider clientId={clientId}>
              <GoogleLogin
                disabled={disable}
                width={300}
                onSuccess={handleGoogle}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            </GoogleOAuthProvider>

            {/*
            <label className="signup">
              {t('login.signUp')} <Link to="/auth/register">{t('login.here')}</Link>
            </label>
            */}
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
}
