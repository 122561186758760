import { useState } from 'react';
import Flex from '../../../components/Flex/Flex';
import { Pencil, Trash } from 'lucide-react';
import { formatCurrency } from '../../../utils/Utils';

const ProductCard = ({ product, onEdit, onDelete }) => {
  const [swiped, setSwiped] = useState(false);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX); // Registro inicial de la posición táctil
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX); // Actualiza la posición del movimiento táctil
  };

  const handleTouchEnd = () => {
    // Si el usuario desliza más de 75px a la izquierda, consideramos que hizo un swipe
    if (touchStart - touchEnd > 75) {
      setSwiped(true);
    }
    // Si el usuario desliza a la derecha, vuelve la tarjeta a su estado inicial
    if (touchEnd - touchStart > 75) {
      setSwiped(false);
    }
  };

  return (
    <div className="product-card-container">
      <div
        className={`product-card ${swiped ? 'swiped' : ''}`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className="product-info">
          <h3>{product.name}</h3>
          <p>
            {formatCurrency(product.price)} x {product.Unit.name}
          </p>
          {/*<p>Cantidad: {product.quantity} gramos</p>*/}
        </div>
        <Flex justifyCenter alignCenter className="swipe-options" gap="10px">
          <Pencil onClick={() => onEdit(product)} color="#FFF" />
          <Trash onClick={() => onDelete(product)} color="#FFF" />
        </Flex>
      </div>
    </div>
  );
};

export default ProductCard;
