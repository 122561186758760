import axios from 'axios';
import { getRefresh, getToken } from './Auth';
import { useAuth } from '../hooks/AuthProvider';

const TENANT = process.env.REACT_APP_TENANT || TENANT;
const environment = process.env.REACT_APP_ENVIROMENT;
const apiUrl = process.env[`REACT_APP_API_URL_${environment}`] || 'http://localhost:3000';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = apiUrl;

axios.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }
    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refreshToken = async () => {
  const refresh = getRefresh();
  const response = await axios.post(`${apiUrl}/users/refresh-token`, { token: refresh });
  return response.data.accessToken;
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // Manejo global de errores
    if (error.response) {
      //   console.error('Error Response:', error.response);
      const originalConfig = error.config;

      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const newAccessToken = await refreshToken();

          originalConfig.headers.authorization = newAccessToken;

          const aca = JSON.parse(localStorage.getItem(TENANT));
          aca.token = newAccessToken;

          localStorage.setItem(TENANT, JSON.stringify(aca));

          return axios(originalConfig);
        } catch (refreshError) {
          throw error;
        }
      }

      switch (error.response.status) {
        case 400:

        case 403:
          // Manejo para errores de autenticación
          // Ejemplo: redirigir a la página de inicio de sesión

          break;

          // Manejo para errores de permisos
          try {
            const newAccessToken = await refreshToken(); // Intenta refrescar el token

            // Guarda el nuevo token en el almacenamiento local o donde sea necesario
            localStorage.setItem('accessToken', newAccessToken);

            // Actualiza la cabecera Authorization para la solicitud original
            error.config.headers.Authorization = `${newAccessToken}`;

            // Vuelve a enviar la solicitud original
            return axios(error.config);
          } catch (refreshError) {
            // Si el refresh token también falla, redirigir al login o manejar el error
            console.error('Error al refrescar el token:', refreshError);
            // Redirigir a la página de inicio de sesión o mostrar un mensaje
            break;
          }
          break;
        case 404:
          // Manejo para recursos no encontrados
          throw error;
          break;
        case 500:
          // Manejo para errores del servidor
          break;
        default:
          // Manejo para otros errores
          break;
      }
    } else if (error.request) {
      // La solicitud fue hecha pero no se recibió respuesta
      console.error('Error Request:', error.request);
    } else {
      // Algo sucedió al configurar la solicitud que desencadenó un error
      //console.error('Error Message:', error.message);
    }

    return Promise.reject(error);
  }
);

export default axios;
