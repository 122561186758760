import { useState } from 'react';
import Button from '../../../../components/Button/Button';
import Flex from '../../../../components/Flex/Flex';
import Payment from '../Payment/Payment';

export default function Step3({ getTotalSale, payments, handleStep, handleEnd, t }) {
  const [paymentId, sePaymentId] = useState(payments[0].id);
  const handlePayment = (e) => {
    sePaymentId(e.target.value);
  };
  return (
    <>
      <div className="content">
        <Payment t={t} handlePayment={handlePayment} total={getTotalSale()} data={payments} />
      </div>
      <Flex gap={'50px'} alignCenter justifyCenter className="footer">
        <Button handleClick={() => handleStep(-1)} iconName={'ArrowLeft'} iconColor="white" label={t('back')} primary />

        <Button
          handleClick={() => handleEnd({ paymentId: paymentId })}
          selected
          iconName={'DollarSign'}
          iconColor="white"
          label={t('close')}
          primary
        />
      </Flex>
    </>
  );
}
