import React from 'react';
import './About.scss'; // Asegúrate de crear este archivo CSS para estilos
import Flex from '../../components/Flex/Flex';
import { Link } from 'react-router-dom';
import { House } from 'lucide-react';
export default function About() {
  return (
    <Flex column className="about">
      <Flex alignCenter className="header">
        <Link to="/">
          <House color="#FFF" />
        </Link>
      </Flex>
      <Flex column className="about-section">
        <h2>¿Cómo Funciona HUBIFY?</h2>
        <p>
          <strong>En HUBIFY, estamos comprometidos a ofrecer un sistema de ventas accesible para todos.</strong>
          Sabemos que muchos emprendedores y pequeñas empresas necesitan herramientas efectivas sin el costo elevado de
          los sistemas tradicionales. Por eso, hemos diseñado nuestra app con un modelo de negocio que permite a todos
          acceder a funcionalidades esenciales de forma gratuita.
        </p>

        <h3>¿Por qué ofrecemos una versión gratuita?</h3>
        <ul>
          <li>
            <strong>Inclusividad:</strong> Queremos que todos, independientemente de su situación económica, puedan
            utilizar HUBIFY y beneficiarse de sus características.
          </li>
          <li>
            <strong>Crecimiento:</strong> Al ofrecer una app gratuita, ayudamos a las pequeñas empresas a crecer y
            prosperar, lo que a su vez beneficia a la comunidad en general.
          </li>
        </ul>

        <h3>¿Cómo se financia HUBIFY?</h3>
        <ol>
          <li>
            <strong>Donaciones Voluntarias:</strong> Si encuentras valor en HUBIFY y deseas contribuir, puedes hacer una
            donación. Cada aporte, sin importar su tamaño, ayuda a mantener la app en funcionamiento y a desarrollar
            nuevas funcionalidades.
          </li>
          <li>
            <strong>Planes de Personalización:</strong> Para aquellos que desean funcionalidades avanzadas, ofrecemos
            opciones de personalización por una tarifa mensual. Esto permite a los usuarios adaptar la app a sus
            necesidades específicas.
          </li>
        </ol>

        <h3>Tu Uso Hace la Diferencia</h3>
        <p>
          Cada vez que utilizas HUBIFY, estás ayudando a mantener la app disponible para otros. Tu apoyo nos permite
          continuar mejorando la experiencia y asegurando que más personas tengan acceso a herramientas que facilitan la
          gestión de sus ventas.
        </p>

        <p>
          <strong>Gracias por ser parte de la comunidad de HUBIFY!</strong> Juntos, podemos crear un espacio donde todos
          tengan la oportunidad de crecer y tener éxito.
        </p>
        <p>El equipo de HUBIFY</p>
      </Flex>
    </Flex>
  );
}
