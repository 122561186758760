import { useRef, useState } from 'react';
import Flex from '../../../../components/Flex/Flex';
import CurrencyInput from 'react-currency-input-field';
import { formatCurrency } from '../../../../utils/Utils';
import './Payment.scss';

export default function Payment({ data, total, handlePayment, t }) {
  const [cash, setCash] = useState('');
  const [result, setResult] = useState(0);

  const handleCash = (value) => {
    setCash(value);
    setResult(-(parseFloat(total) - value));
  };

  return (
    <Flex className="payment" column>
      <label>{t('payment')}</label>

      <select onChange={handlePayment}>
        {data.map((item, index) => (
          <option key={`___${index}`} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>

      <Flex column className="form none">
        <label>{t('totalOrder')}</label>
        <Flex className="total black">{formatCurrency(total, t('currency'))}</Flex>
        <label>{t('money')}</label>
        <CurrencyInput value={cash} required onValueChange={(value) => handleCash(value || '')} decimalsLimit={2} />
        <label>{t('change')}</label>
        <Flex className="total black">{formatCurrency(result, t('currency'))}</Flex>
      </Flex>
    </Flex>
  );
}
