import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useToast from '../../hooks/useToast';
import DonationService from '../../services/Donation.service';

export default function useDonate() {
  const [donate, setDonate] = useState(false);
  const [submited, setSubmited] = useState(false);
  const { t } = useTranslation();
  const { updateToast } = useToast();

  const handleSubmit = async (data) => {
    setSubmited(true);
    try {
      const result = await DonationService.create({ data: data });
      if (result) {
        updateToast({ text: t('donation.thanks') });
      }
    } catch (error) {
      updateToast({ text: error });
    }
  };

  const handleDonate = () => {
    setDonate(true);
  };

  return { t, submited, donate, handleSubmit, handleDonate };
}
