export const es = {
  a: 'a',
  about: 'Acerca de ',
  cancel: 'Cancelar',
  ok: 'Ok',
  hi: '¡Hola, ',
  burgers: 'Burgers',
  quantity: 'Cantidad',
  combos: 'Combos',
  next: 'Siguiente ',
  drinks: 'Bebidas',
  cancel: 'Cancelar',
  back: 'Volver',
  end: 'Finalizar',
  close: 'Cerrar pedido',
  closeSale: 'Finalizar venta',
  payment: 'Forma de pago',
  totalOrder: 'Total pedido',
  money: 'Dinero',
  welcome: 'Bienvenido',
  orders: 'Pedidos',
  find: 'Buscar pedido',
  exit: 'Salir',
  additionals: 'Adicionales',
  statistics: 'Estadísticas',
  chooseUnit: 'Elegir unidad',
  exclude: 'Quitar',
  search: 'Buscar',
  change: 'Vuelto',
  orderCreated: 'Orden creada',
  saleCreated: 'Venta creada',
  delete: 'Borrar',
  sales: 'Vender',
  report: 'Reporte diario',
  totalSale: 'Hoy vendiste:',
  personalize: 'Personalizar',
  donate: 'Donar',
  feed: 'Feedback',
  loading: 'Cargando...',
  qr: 'Pagos disponibles',
  privacy: 'Política de privacidad',
  unit: 'Unidad',
  cost: 'Costo',
  price: 'Precio',
  ingredients: 'Ingredientes',
  save: 'Guardar',
  mySpace: 'Mi tienda',
  noItem: 'Em la venta existen item con cantidad vacía',
  products: {
    name: 'Nombre',
    loading: 'Cargando más productos...',
    move: 'Desplázate para cargar más...',
    search: 'Buscar productos...',
    selected: 'Este producto ya ha sido seleccionado.',
    product: 'Producto',
    text: 'Productos',
    created: 'Producto creado',
    updated: 'Producto actualizado',
    confirm: '¿Estás seguro de que deseas eliminar este producto?',
    deleted: 'Producto removido',
    nomore: 'No hay más productos para cargar.',
  },
  brand: {
    default: {
      speech: 'Semper altius',
      loginSpeech: 'Bienvenido a Nim Food, una nueva forma de vender más y mejor.',
    },
    hubify: {
      speech: 'Semper altius',
      loginSpeech: 'Bienvenido a Hubify, una nueva forma de vender más y mejor.',
    },
  },
  login: {
    account: 'Administrar cuenta',
    email: 'Correo electrónico',
    here: 'aquí',
    myProfile: 'Mi perfil',
    or: 'o',
    password: 'Contraseña',
    retype: 'Repetir contraseña',
    newPassword: 'Nueva contraseña',
    signIn: 'Iniciar sesión',
    signOut: 'Cerrar sesión',
    signUp: 'Registrarse',
    init: 'Iniciar sesión',
    passwordError: 'Las contraseñas no coinciden.',
    passwordMin: 'La contraseña mínimo 8 caracteres',
  },
  currency: {
    sign: '$',
    format: 'es-AR',
    letter: 'ARS',
  },
  order: {
    title: 'Detalle de la orden',
    resume: 'Resumen',
    date: 'Fecha',
    total: 'Total',
    cook: 'Tipo de cocción',
    additionals: 'Adicionales',
    drinks: 'Bebidas y otros',
    payment: 'Pago',
    method: 'Método de pago',
    user: 'Usuario',
    name: 'Nombre',
    notFound: 'Orden no encontrada',
  },
  feedback: {
    100: 'Las ventas han aumentado un 100% o más respecto a ayer, que no hubo ventas.',
    0: 'No hay ventas registradas ni ayer ni hoy.',
    custom: 'Las ventas han cambiado un {{data}} respecto a ayer.`',
    noSales: 'No tienes ventas hoy',
  },
  feedbackSection: {
    thanks: '¡Gracias por tu feedback y participación!',
    title: 'Déjanos tu Feedback',
    type: 'Tipo de Feedback:',
    types: ['Sugerencia', 'Reporte de Error', 'Mejora en la Versión Gratuita', 'Funcionalidades Premium'],
    placeHolder: 'Escribe aquí tus sugerencias o comentarios...',
    question: '¿Qué tan satisfecho estás con la versión gratuita?',
    option: 'Selecciona una opción',
    options: ['Muy insatisfecho', 'Insatisfecho', 'Neutral', 'Satisfecho', 'Muy satisfecho'],
    send: 'Enviar',
    sended: 'Feedback enviado',
    only: 'Solo en versión premium',
  },
  donation: {
    thanks: '¡Gracias por tu donación!',
    select: 'Selecciona tu donación',
    personalize: 'Cantidad personalizada:',
    donate: '¡Haz una donación!',
    registerDonation: 'Registrar Intención de Donación',
    perMonth: 'Hacer esta donación de manera mensual',
    warn: 'Por favor, selecciona o ingresa una cantidad válida.',
    step1: {
      support_title: '¡Tu apoyo marca la diferencia!',
      support_description:
        'En HUBIFY, creemos que todos merecen acceso a herramientas de ventas simples y efectivas. Por eso, ofrecemos nuestra app de forma gratuita para quienes necesitan un sistema que les ayude a crecer sin la carga de costos elevados.',
      why_donate_title: '¿Por qué donar?',
      donate_reasons: {
        sustainability:
          'Sostenibilidad: Las donaciones nos permiten mantener la app gratuita y seguir mejorando nuestras funcionalidades.',
        help_others:
          'Ayuda a otros: Tu contribución no solo apoya el desarrollo de HubiFy, sino que también permite que más personas puedan acceder a herramientas útiles sin costo.',
        value_for_all:
          'Valor para todos: Cada donación, grande o pequeña, ayuda a construir una comunidad donde todos pueden prosperar.',
      },
      ready_to_help_title: '¿Listo para ayudar?',
      ready_to_help_description:
        'Si te gusta HubiFy y sientes que te está ayudando en tu negocio, considera hacer una donación. ¡Cualquier aporte cuenta!',
    },

    makeDonation: 'Hacer una Donación',
    values: [1000, 3000, 5000, 10000, 20000],
  },
  profile: {
    info: ' Asegúrate de que tu nueva contraseña tenga al menos 8 caracteres y contenga letras, números y símbolos.',
    unsuscribeInfo: 'Si decides darte de baja, perderás el acceso a tu cuenta y a tus datos.',
    remember:
      'La nueva contraseña debe tener al menos 8 caracteres, incluir una letra mayúscula, una minúscula, un número y un carácter especial.',
    decision: 'Decido darme de baja',
    text: 'Perfil de usuario',
    name: 'Nombre:',
    lastName: 'Apellido:',
    email: 'Email:',
    changePassword: 'Cambiar contraseña',
    oldPassword: 'Contraseña anterior',
    repeatNewPassword: 'Repetir contraseña',
    newPassword: 'Nueva contraseña',
    logout: 'Cerrar sesión',
    unsuscribe: 'Darse de baja',
    noMatch: 'Las contraseñas nuevas no coinciden',
    passwordChanged: 'Contraseña cambiada',
    invalidPassword: 'La contraseña anterior no es válida',
  },
};
