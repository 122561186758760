import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FeedbackService from '../../services/Feedback.service';
import confetti from 'canvas-confetti';
import useToast from '../../hooks/useToast';

export default function useFeedback() {
  const [feedbackType, setFeedbackType] = useState('sugerencia');
  const [feedback, setFeedback] = useState('');
  const [surveyResponse, setSurveyResponse] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();
  const { updateToast } = useToast();
  const [sending, setSending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    const payload = {
      feedbackType: feedbackType,
      feedback: feedback,
      surveyResponse: surveyResponse,
    };

    try {
      const result = await FeedbackService.create({ data: payload });
      setSubmitted(true);
      confetti();
      if (result) {
        updateToast({ text: t('feedbackSection.sended') });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFeedbackType = (e) => {
    setFeedbackType(e.target.value);
  };

  const handleFeedback = (e) => {
    setFeedback(e.target.value);
  };

  const handleSurveyResponse = (e) => {
    setSurveyResponse(e.target.value);
  };

  return {
    t,
    submitted,
    sending,
    feedbackType,
    feedback,
    surveyResponse,
    handleSubmit,
    handleFeedbackType,
    handleFeedback,
    handleSurveyResponse,
  };
}
