import React from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';

import Flex from '../../components/Flex/Flex';
import useDaily from './useDaily';
import './Daily.scss';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../utils/Utils';
import Loader from '../../components/Loader/Loader';
import { Annoyed, House } from 'lucide-react';

export default function Daily() {
  const { data, percent, t, today } = useDaily();

  if (!data) return <Loader />;
  return (
    <Flex className="daily" column>
      <Flex alignCenter className="header">
        <Link to="/">
          <House color="#FFF" />
        </Link>
      </Flex>
      <Flex column className="wrapper">
        <Flex column>
          {data.length > 0 ? (
            <>
              <div className="summary-card">
                <h2>{t('totalSale')}</h2>
                <p className="total-amount">{formatCurrency(today)}</p>
                <p className="feedback-message">{percent}</p>
              </div>

              <div style={{ width: '95%', height: 200, marginTop: 50 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={data}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Bar dataKey="value" fill="var(--color-button)" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </>
          ) : (
            <>
              <h4>Sin registros</h4>
              <Annoyed color="var(--color-button)" />
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
