import React from 'react';
import './Donation.scss'; // Estilos CSS para el componente
import Flex from '../../../components/Flex/Flex';
import Button from '../../../components/Button/Button';
import useDonation from './useDonation';
import { formatCurrency } from '../../../utils/Utils';
import { Link } from 'react-router-dom';
import CheckBox from '../../../components/Checkbox/CheckBox';

const Donation = ({ t, onSubmit }) => {
  const {
    selectedAmount,
    customAmount,
    isMonthly,
    handleAmountClick,
    handleCustomAmountChange,
    handleByMonth,
    handleSubmit,
  } = useDonation({
    onSubmit,
    t,
  });
  return (
    <form onSubmit={handleSubmit} className="donation-form">
      <h2>{t('donation.select')}</h2>

      <Flex column className="donation-buttons">
        {t('donation.values').map((item, index) => (
          <Button
            active={index === selectedAmount}
            handleClick={() => handleAmountClick(index)}
            primary
            label={formatCurrency(item)}
            key={`__${index}`}
          />
        ))}
      </Flex>

      <Flex alignCenter className="custom-amount">
        <label htmlFor="custom-amount">{t('donation.personalize')}</label>
        <input
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          placeholder="Introduce tu cantidad"
          value={customAmount}
          onChange={handleCustomAmountChange}
        />
      </Flex>

      <Flex alignCenter spaceBtw className="monthly-checkbox">
        <small>{t('donation.perMonth')}</small>
        <CheckBox checked={isMonthly} handleCheckBox={handleByMonth} />
      </Flex>

      <p>
        Si necesitas más información sobre cómo se utilizan las donaciones, puedes visitar nuestra{' '}
        <Link className="about" to="https://hubify.com.ar/donate.html" target="_blank">
          página de donaciones
        </Link>
        .
      </p>

      <Button full primary type="submit" label={t('donation.registerDonation')} />
    </form>
  );
};

export default Donation;
