import CryptoJS from 'crypto-js';
const SECRET_KEY = process.env.REACT_APP_SECRET;

const sanitizeConf = {
  allowedTags: ['b', 'i', 'a', 'p', 'ul', 'li', 'ol', 'em', 'strong'],
  allowedAttributes: {},
  nonBooleanAttributes: ['*'],
};

const formatCurrency = (value, currency) => {
  return parseFloat(value).toLocaleString(currency?.format || 'es-AR', {
    style: 'currency',
    currency: currency?.letter || 'ARS',
  });
};

const getWeekRange = (date = new Date()) => {
  const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay())); // Domingo
  const endOfWeek = new Date(date.setDate(startOfWeek.getDate() + 6)); // Sábado

  // Formateamos las fechas en formato YYYY-MM-DD para enviarlas al backend
  const startDate = startOfWeek.toISOString().split('T')[0];
  const endDate = endOfWeek.toISOString().split('T')[0];

  return { startDate, endDate };
};

const getDayOfWeek = () => {
  return {
    2: 'Lun', // Lunes
    3: 'Mar', // Martes
    4: 'Mié', // Miércoles
    5: 'Jue', // Jueves
    6: 'Vie', // Viernes
    7: 'Sáb', // Sábado
    1: 'Dom', // Domingo (se moverá al final)
  };
};

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

const decryptData = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export { sanitizeConf, formatCurrency, getWeekRange, getDayOfWeek, encryptData, decryptData };
