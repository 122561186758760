import { Link } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Flex from '../../components/Flex/Flex';
import Loader from '../../components/Loader/Loader';
import Step1 from './Components/Steps/Step1';
import Step2 from './Components/Steps/Step2';
import Step3 from './Components/Steps/Step3';
import './Orders.scss';
import useOrders from './useOrders';

export default function Orders() {
  const {
    data,
    productsSelected,
    handleReset,
    handleSelect,
    handleFilterSelect,
    filterSelect,
    handleQty,
    handleStep,
    step,
    cookTypes,
    getTotalSale,
    payments,
    handleEnd,
    handleSetproducts,
    drinks,
    continueEnabled,
    user,
    order,
    t,
  } = useOrders();

  if (!data) return <Loader />;

  return (
    <div className="orders">
      <Flex gap={'2px'} column className="form">
        <Flex alignCenter spaceBtw>
          <Link to="/">
            <Button iconName={'Home'} iconColor="#FFF" iconSize={26} />
          </Link>
          <div className="order">{`#${order}`}</div>
          <small>{user}</small>
        </Flex>
      </Flex>
      {step === 1 && (
        <Step1
          t={t}
          data={data}
          handleFilterSelect={handleFilterSelect}
          filterSelect={filterSelect}
          handleQty={handleQty}
          handleSelect={handleSelect}
          handleReset={handleReset}
          handleStep={handleStep}
          continueEnabled={continueEnabled}
        />
      )}
      {step === 2 && (
        <Step2
          t={t}
          user={user}
          getTotalSale={getTotalSale}
          handleSetproducts={handleSetproducts}
          cookTypes={cookTypes}
          drinks={drinks}
          products={productsSelected}
          handleStep={handleStep}
        />
      )}
      {step === 3 && (
        <Step3 t={t} getTotalSale={getTotalSale} payments={payments} handleStep={handleStep} handleEnd={handleEnd} />
      )}
    </div>
  );
}
