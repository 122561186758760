import React from 'react';
import Select from 'react-select';
import './Product.scss';
import Flex from '../../../components/Flex/Flex';
import Button from '../../../components/Button/Button';
import { ArrowLeft, Plus, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import CurrencyInput from 'react-currency-input-field';
import useProduct from './useProduct';
import Loader from '../../../components/Loader/Loader';

const NewProductForm = () => {
  const {
    t,
    formRef,
    units,
    unit,
    product,
    ingredients,
    getAvailableIngredients,
    removeIngredientField,
    addIngredientField,
    handleSubmit,
    handleIngredientChange,
    handleKeyDown,
    handleQuantityChange,
    handleUnitChange,
    handleInputProduct,
  } = useProduct();

  if (!units) return <Loader />;

  return (
    <div className="products" ref={formRef}>
      <Flex gap={'2px'} column className="header">
        <Flex alignCenter>
          <Link to="/products">
            <ArrowLeft color="#FFF" />
          </Link>
          <h3>{t('products.product')}</h3>
        </Flex>
      </Flex>
      <div className="form new-product-form-container">
        <form className="new-product-form" onSubmit={handleSubmit}>
          <label htmlFor="name">{t('products.name')}</label>
          <input
            type="text"
            name="name"
            value={product.name}
            onChange={handleInputProduct}
            placeholder={t('products.name')}
            required
          />

          {/*
         <label htmlFor="description">Descripción</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Descripción del producto"
            rows="3"
          />
         */}

          <label htmlFor="description">{t('unit')}</label>
          <select required value={product.UnitId} onChange={handleUnitChange}>
            <option value="">{t('chooseUnit')}</option>
            {units.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>

          <label htmlFor="cost">{t('cost')}</label>
          <CurrencyInput
            onValueChange={(value) => handleInputProduct({ target: { name: 'extras', value: value } })}
            required
            value={product.extras}
            decimalsLimit={2}
          />

          <label htmlFor="price">{t('price')}</label>
          <CurrencyInput
            onValueChange={(value) => handleInputProduct({ target: { name: 'price', value: value } })}
            required
            value={product.price}
            decimalsLimit={2}
          />

          <Flex column className="ingredient-wrapper">
            <Flex spaceBtw alignCenter>
              <h4>{t('ingredients')}</h4>
              <Plus onClick={addIngredientField} />
            </Flex>

            {ingredients.map((ingredient, index) => (
              <Flex alignCenter key={index} className="ingredient-row">
                <div clasName="ingredient-select">
                  <Select
                    value={ingredient.ingredient}
                    onChange={(selectedOption) => handleIngredientChange(index, selectedOption)}
                    options={getAvailableIngredients(index)}
                    placeholder={t('ingredients')}
                    menuPlacement="auto"
                  />
                </div>

                <input
                  type="number"
                  placeholder={t('quantity')}
                  className="ingredient-quantity"
                  value={ingredient.quantity}
                  onChange={(e) => handleQuantityChange(index, e.target.value)}
                  step="0.01"
                  onKeyDown={handleKeyDown}
                />

                <X onClick={() => removeIngredientField(index)} />
              </Flex>
            ))}
          </Flex>

          <Button label={t('save')} type="submit" primary />
        </form>
      </div>
    </div>
  );
};

export default NewProductForm;
