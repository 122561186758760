import axios from './Client';

const get = async () => {
  const response = await axios.get(`/feedback`);
  return response.data;
};

const put = async ({ id, data }) => {
  const response = await axios.put(`/feedback/${id}`, data);
  return response.data;
};

const del = async ({ id }) => {
  const response = await axios.delete(`/feedback/${id}`);
  return response.data;
};

const create = async ({ data }) => {
  const response = await axios.post(`/feedback`, data);
  return response.data;
};

export default { get, put, create, del };
