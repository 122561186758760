import React, { useState } from 'react';
import './Feedback.scss';
import Flex from '../../components/Flex/Flex';
import { Link } from 'react-router-dom';
import { House } from 'lucide-react';
import Button from '../../components/Button/Button';
import useFeedback from './useFeedback';

const Feedback = () => {
  const {
    t,
    submitted,
    sending,
    feedbackType,
    feedback,
    surveyResponse,
    handleSubmit,
    handleFeedbackType,
    handleFeedback,
    handleSurveyResponse,
  } = useFeedback();

  return (
    <Flex column className="feedback">
      <Flex alignCenter className="header">
        <Link to="/">
          <House color="#FFF" size={32} />
        </Link>
      </Flex>

      <Flex column className="feedback-form">
        <p className={`success-message${submitted ? ' show' : ''}`}>{t('feedbackSection.thanks')}</p>
        {!submitted && (
          <form onSubmit={handleSubmit}>
            <h2>{t('feedbackSection.title')}</h2>

            <label htmlFor="feedbackType">{t('feedbackSection.type')}</label>
            <select required value={feedbackType} onChange={handleFeedbackType}>
              {t('feedbackSection.types').map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>

            <textarea
              maxLength={300}
              value={feedback}
              onChange={handleFeedback}
              placeholder={t('feedbackSection.placeHolder')}
              required
            />

            <label htmlFor="surveyResponse">{t('feedbackSection.question')}</label>
            <select required value={surveyResponse} onChange={handleSurveyResponse}>
              <option value="">{t('feedbackSection.option')}</option>
              {t('feedbackSection.options').map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>

            <Flex justifyEnd>
              <Button disable={sending} label={t('feedbackSection.send')} primary type="submit" />
            </Flex>
          </form>
        )}
      </Flex>
    </Flex>
  );
};

export default Feedback;
