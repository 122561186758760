import useSales from './useSales';
import './Sales.scss';
import Flex from '../../components/Flex/Flex';
import { Link } from 'react-router-dom';
import { House, Trash } from 'lucide-react';
import { formatCurrency } from '../../utils/Utils';
import BottomBar from './components/BottomBar';

export default function Sales() {
  const {
    t,
    query,
    loading,
    products,
    total,
    selectProduct,
    selectedProducts,
    handleInputChange,
    handleQuantityChange,
    handleDeleteProduct,
    handleSaveSale,
    handleReset,
  } = useSales();

  return (
    <div className="product-search-container">
      <Flex alignCenter className="header">
        <Link to="/">
          <House color="#FFF" />
        </Link>
        <h3>{t('sales')}</h3>
      </Flex>
      <Flex alignCenter justifyCenter className="total">
        <p>{formatCurrency(total)}</p>
      </Flex>
      <div className="wrapper">
        <Flex justifyCenter alignCenter column className="input">
          <input type="text" value={query} onChange={handleInputChange} placeholder={t('products.search')} />
          {loading ? (
            <p>{t('loading')}</p>
          ) : (
            <div id="search-results">
              {products.length > 0
                ? products.map((product) => (
                    <div key={product.id} className="result-item" onClick={() => selectProduct(product)}>
                      {product.name}
                    </div>
                  ))
                : query.length >= 3 && <div className="no-results">No se encontraron productos.</div>}
            </div>
          )}
        </Flex>
        <Flex column>
          {selectedProducts.map((product) => (
            <Flex spaceBtw alignCenter className="product" key={product.id}>
              <Flex column>
                <h3>{product.name}</h3>
                <p>
                  {formatCurrency(product.price)} x {product.Unit.name}
                </p>
              </Flex>
              <Flex className="inputs" alignCenter justifyEnd gap="10px">
                <input
                  min={0}
                  step={0.5}
                  type="number"
                  value={product.quantity}
                  onChange={(e) => handleQuantityChange(e, product.id)}
                />
                <p>{isNaN(product.quantity) ? formatCurrency(0) : formatCurrency(product.quantity * product.price)}</p>
                <Trash onClick={() => handleDeleteProduct(product.id)} />
              </Flex>
            </Flex>
          ))}
        </Flex>
      </div>
      <BottomBar t={t} disabled={!selectedProducts.length} onSave={handleSaveSale} onCancel={handleReset} />
    </div>
  );
}
