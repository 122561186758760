import { useEffect, useState } from 'react';
import OrderService from '../../services/Order.service';
import useToast from '../../hooks/useToast';
import { useTranslation } from 'react-i18next';
import { getDayOfWeek, getWeekRange } from '../../utils/Utils';

export default function useDaily() {
  const [data, setData] = useState(null);
  const [percent, setPercent] = useState('');
  const { updateToast } = useToast();
  const [today, setToday] = useState(0);
  const { t } = useTranslation();

  function getTodayAndYesterdaySales(salesData) {
    const todayIndex = new Date().getDay();
    const todaySales = salesData[todayIndex].value;
    const yesterdayIndex = todayIndex - 1;

    setToday(todaySales);

    const yesterdaySales = salesData[yesterdayIndex].value;
    if (todaySales === 0 && yesterdaySales > 0) {
      setPercent(t('feedback.noSales'));
      return;
    }
    if (yesterdaySales === 0) {
      setPercent(todaySales > 0 ? t('feedback.100') : t('feedback.0'));
      return;
    }

    const difference = todaySales - yesterdaySales;
    const percent = Math.round((difference / yesterdaySales) * 100, 2);
    setPercent(t('feedback.custom', { data: `${percent}%` }));
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        const { startDate, endDate } = getWeekRange();
        const response = await OrderService.getSalesWeekReport({ startDate: startDate, endDate: endDate });
        const weekDaysMap = getDayOfWeek();

        const result = Object.keys(weekDaysMap).map((dayOfWeek) => ({
          name: weekDaysMap[dayOfWeek],
          value: 0,
        }));

        response.forEach(({ dayOfWeek, total }) => {
          const index = dayOfWeek - 1;
          result[index].value = parseFloat(total);
        });

        getTodayAndYesterdaySales(result);

        setData(result);
      } catch (error) {
        updateToast({ text: error.message });
      }
    };
    fetch();
  }, []);

  return { data, percent, t, today };
}
