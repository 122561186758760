import Flex from '../../components/Flex/Flex';
import useDonate from './useDonate';
import './Donate.scss';
import { Link } from 'react-router-dom';
import { House } from 'lucide-react';
import Button from '../../components/Button/Button';
import Donation from './components/Donation';

export default function Donate() {
  const { t, submited, donate, handleSubmit, handleDonate } = useDonate();
  return (
    <Flex column className="donation">
      <Flex alignCenter className="header">
        <Link to="/">
          <House color="#FFF" />
        </Link>
      </Flex>
      <div className="content-wrapper">
        <Flex column className="donation-section">
          {!donate && (
            <>
              <h2>{t('donation.step1.support_title')}</h2>
              <p>
                <strong>{t('donation.step1.support_description')}</strong>
              </p>
              <h3>{t('donation.step1.why_donate_title')}</h3>
              <ul>
                <li>
                  <strong>{t('donation.step1.donate_reasons.sustainability')}</strong>
                </li>
                <li>
                  <strong>{t('donation.step1.donate_reasons.help_others')}</strong>
                </li>
                <li>
                  <strong>{t('donation.step1.donate_reasons.value_for_all')}</strong>
                </li>
              </ul>
              <h3>{t('donation.step1.ready_to_help_title')}</h3>
              <p>{t('donation.step1.ready_to_help_description')}</p>
              <Button disable={submited} handleClick={handleDonate} primary label={t('donation.donate')} />
            </>
          )}
          {donate && <Donation t={t} onSubmit={handleSubmit} />}
        </Flex>
      </div>
    </Flex>
  );
}
