import Flex from '../../components/Flex/Flex';
import { Link } from 'react-router-dom';
import './Home.scss';
import Pill from './pill/Pill';
import useHome from './useHome';
import Button from '../../components/Button/Button';
import { User } from 'lucide-react';

export default function Home() {
  const { user, t, handleDecision } = useHome();

  return (
    <Flex className="home" column>
      <Flex className="home__header" spaceBtw>
        <Flex column>
          <Flex gap="15px" alignCenter>
            <img className="home__header__logo" src={`/${process.env.REACT_APP_TENANT}/assets/logo.png`} />
            <p className="home__header__title">Hubify</p>
          </Flex>
        </Flex>
        <Link className="home__header__profile" to="/profile">
          <User size={32} />
        </Link>
      </Flex>

      <Flex column className="home__body">
        <Flex justifyCenter alignCenter column className="home__body__buttons">
          <Flex alignCenter>
            <small className="home__header__subtitle">{`${t('welcome')} ${`${user.name} ${user.surname}`}`}</small>
          </Flex>
          <Pill handleClick={handleDecision} t={t} />
          <Flex alignCenter className="home__body__about">
            <p>
              {t('about')}
              <Link className="home__body__about-link" to={'/about'}>
                HUBIFY
              </Link>
            </p>
          </Flex>
        </Flex>

        <Flex alignCenter className="home__footer" column>
          <small className="home__footer__version">{`${t('version')} ${process.env.REACT_APP_VERSION}`}</small>
        </Flex>
      </Flex>
    </Flex>
  );
}
