import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/AuthProvider';
import ProductsService from '../../services/Products.service';
import CookTypesService from '../../services/CookTypes.service';
import AdditionalsService from '../../services/Additionals.service';
import PaymentsService from '../../services/Payments.service';
import DrinkService from '../../services/Drink.service';
import OrderService from '../../services/Order.service';
import { useTranslation } from 'react-i18next';
import useToast from '../../hooks/useToast';
import MenusService from '../../services/Menus.service';

export default function useOrders() {
  const { user } = useAuth();
  const { updateToast } = useToast();
  const [data, setData] = useState(null);
  const [step, setStep] = useState(1);
  const [filterSelect, setFilterSelect] = useState(0);
  const [cookTypes, setCookTypes] = useState([]);
  const [payments, setPayments] = useState([]);
  const [products, setProducts] = useState([]);
  const [combos, setCombos] = useState([]);
  const [drinks, setDrinks] = useState([]);
  const [order, setOrder] = useState(1);
  const [productsSelected, setProductsSelected] = useState([]);
  const { t } = useTranslation();
  const dataMap = {
    0: products,
    1: combos,
    default: drinks,
  };

  const handleSelect = (item) => {
    const newerData = data.map((it) =>
      it.id === item.id ? { ...it, checked: !it.checked, quantity: !it.checked ? 1 : it.quantity } : it
    );
    setData(newerData);

    updateOriginal(newerData);
  };

  const updateOriginal = (newerData) => {
    if (data === products) {
      setProducts(newerData);
    } else if (data === combos) {
      setCombos(newerData);
    } else {
      setDrinks(newerData);
    }
  };

  const handleReset = () => {
    setDrinks(drinks.map((item) => ({ ...item, checked: false, quantity: 1, combo: false })));
    setProducts(products.map((item) => ({ ...item, checked: false, quantity: 1 })));
    setCombos(combos.map((item) => ({ ...item, checked: false })));
    handleFilterSelect(0);
    setProductsSelected([]);
    setData(products.map((item) => ({ ...item, checked: false, quantity: 1 })));
    setStep(1);
  };

  const handleFilterSelect = (value) => {
    setFilterSelect(value);
    setData(dataMap[value] || dataMap.default);
  };

  const handleQty = ({ item, qty }) => {
    const updatedData = data.map((itemData) => {
      if (itemData.id === item.id) {
        return {
          ...itemData,
          quantity: qty,
        };
      }
      return itemData;
    });

    setData(updatedData);
    updateOriginal(updatedData);
  };

  const handleSetproducts = (product) => {
    setProductsSelected(product);
  };

  const handleStep = (value) => {
    if (step === 1) {
      const resultado = [];

      combos
        .filter((item) => item.checked)
        .forEach((result) => {
          result.MenuItems.forEach((item) => {
            if (item.Product) {
              for (let i = 0; i < item.quantity; i++) {
                const prod = products.find((prod) => prod.id === item.Product.id);
                const res = JSON.parse(JSON.stringify(prod));
                res.quantity = 1;
                resultado.push(res);
              }
            }
            if (item.Drink) {
              const updatedDrinks = drinks.map((drink) => {
                if (drink.id === item.Drink.id) {
                  return {
                    ...drink,
                    quantity: item.quantity,
                    checked: true,
                    combo: true,
                  };
                }
                return drink;
              });
              setDrinks(updatedDrinks);
            }
          });
        });

      products
        .filter((item) => item.checked)
        .forEach((product) => {
          for (let i = 0; i < product.quantity; i++) {
            const res = JSON.parse(JSON.stringify(product));
            res.quantity = 1;
            resultado.push(res);
          }
        });

      setProductsSelected(resultado);
    }
    setStep(step + value);
  };

  const continueEnabled = () => {
    let prodCheck = data.filter((item) => item.checked).length > 0;
    let drinkCheck = drinks.filter((item) => item.checked).length > 0;
    return prodCheck || drinkCheck;
  };

  const handleEnd = async ({ paymentId }) => {
    const customerOrder = {
      order: {
        UserId: user.id,
        total: getTotalSale(),
        PaymentMethodId: paymentId,
      },
      products: productsSelected.map((product) => {
        return {
          ProductId: product.id,
          CookTypeId: product.cookTypeId,
          price: product.price,
          quantity: product.quantity,
          additionals: product.additionals
            .filter((item) => item.checked)
            .map((item) => ({ AdditionalId: item.id, quantity: item.quantity, price: item.price })),
        };
      }),

      drinks: drinks
        .filter((item) => item.checked)
        .map((item) => ({ DrinkId: item.id, quantity: item.quantity, price: item.price })),
    };

    try {
      const result = await OrderService.post({ data: customerOrder });
      handleReset();
      if (result) {
        setOrder(order + 1);
        updateToast({ text: t('orderCreated') });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalSale = () => {
    let totalAdds = 0;

    productsSelected.forEach((item) => {
      item.additionals &&
        item.additionals
          .filter((item) => item.checked)
          .forEach((it) => {
            totalAdds += parseFloat(it.price) * parseInt(it.quantity);
          });
    });

    let totalCombos = combos
      .filter((item) => item.checked)
      .map((item) => parseFloat(item.price) * parseInt(item.quantity));

    let totalProds = products
      .filter((item) => item.checked)
      .map((item) => parseFloat(item.price) * parseInt(item.quantity));

    let totalDrinks = drinks
      .filter((item) => item.checked && !item.combo)
      .map((item) => parseFloat(item.price) * parseInt(item.quantity));

    return (
      totalProds.reduce((acumulador, actual) => acumulador + actual, 0) +
      totalCombos.reduce((acumulador, actual) => acumulador + actual, 0) +
      totalAdds +
      totalDrinks.reduce((acumulador, actual) => acumulador + actual, 0)
    );
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const [orderRes, drinkRes, paymentRes, additionalRes, cookRes, productRes, comboRes] = await Promise.all([
          OrderService.count(),
          DrinkService.get(),
          PaymentsService.get(),
          AdditionalsService.get(),
          CookTypesService.get(),
          ProductsService.get(),
          MenusService.get(),
        ]);

        setOrder(parseInt(orderRes.count));
        setDrinks(drinkRes.map((item) => ({ ...item, quantity: 1, checked: false })));
        setPayments(paymentRes);
        setCookTypes(cookRes);
        setCombos(comboRes.map((item) => ({ ...item, quantity: 1, checked: false })));

        const add = additionalRes;

        const _data = productRes.map((item) => ({
          ...item,
          selected: false,
          quantity: 1,
          cookTypeId: cookRes[0].id,
          additionals: add.map((item) => ({ ...item, quantity: 1, checked: false })),
        }));
        setProducts(_data);
        setData(_data);
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  const beforeUnloadHandler = (event) => {
    event.preventDefault();
    event.returnValue = true;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, []);

  return {
    data,
    productsSelected,
    handleSelect,
    handleReset,
    handleFilterSelect,
    filterSelect,
    handleQty,
    handleStep,
    step,
    cookTypes,
    getTotalSale,
    payments,
    handleEnd,
    handleSetproducts,
    drinks,
    continueEnabled,
    user: `${user.name} ${user.surname}`,
    order,
    t,
  };
}
