import React from 'react';
import './BottomBar.scss';
import Flex from '../../../components/Flex/Flex';
import Button from '../../../components/Button/Button';

const BottomBar = ({ t, disabled, onSave, onCancel }) => {
  return (
    <Flex alignCenter justifyCenter className="bottom-bar">
      <Button disable={disabled} primary label={t('cancel')} handleClick={onCancel} />
      <Button disable={disabled} primary label={t('closeSale')} handleClick={onSave} />
    </Flex>
  );
};

export default BottomBar;
