import axios from './Client';

const get = async () => {
  const response = await axios.get(`/products`);
  return response.data;
};

const getById = async ({ id }) => {
  const response = await axios.get(`/products/${id}`);
  return response.data;
};

const getByPage = async ({ page }) => {
  const response = await axios.get(`/products/page/${page}`);
  return response.data;
};

const getByQuery = async ({ query }) => {
  const response = await axios.get(`/products/search/${query}`);
  return response.data;
};

const put = async ({ id, data }) => {
  const response = await axios.put(`/products/${id}`, data);
  return response.data;
};

const patch = async ({ id, data }) => {
  const response = await axios.patch(`/products/${id}`, data);
  return response.data;
};

const del = async ({ id }) => {
  const response = await axios.delete(`/products/${id}`);
  return response.data;
};

const post = async ({ data }) => {
  const response = await axios.post(`/products`, data);
  return response.data;
};

export default { get, getByPage, getById, getByQuery, put, patch, post, del };
