import axios from './Client';

const get = async () => {
  const response = await axios.get(`/orders`);
  return response.data;
};

const getById = async ({ id }) => {
  const response = await axios.get(`/orders/${id}`);
  return response.data;
};

const getSalesDailyReport = async ({ date }) => {
  const response = await axios.get(`/orders/sales/daily/${date}`);
  return response.data;
};

const getSalesWeekReport = async ({ startDate, endDate }) => {
  const response = await axios.get(`/orders/sales/week/${startDate}/${endDate}`);
  return response.data;
};

const count = async () => {
  const response = await axios.get(`/orders/count`);
  return response.data;
};

const post = async ({ data }) => {
  const response = await axios.post(`/orders`, data);
  return response.data;
};

export default { get, getById, getSalesDailyReport, getSalesWeekReport, count, post };
