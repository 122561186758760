import React from 'react';
import './Products.scss';
import ProductCard from './components/ProductCard';
import Flex from '../../components/Flex/Flex';
import { Link } from 'react-router-dom';
import useProducts from './useProducts';
import { House, Plus } from 'lucide-react';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';

const Products = () => {
  const {
    t,
    isModalOpen,
    filteredProducts,
    handleDelete,
    handleEdit,
    handleSearchChange,
    handleClickNewProduct,
    handleCloseModal,
    handleOpenModal,
    loadMoreRef,
    loading,
    hasMore,
  } = useProducts();

  return (
    <div className="products">
      <Flex className="header" alignCenter spaceBtw>
        <div className="item">
          <Link to="/">
            <House color="#FFF" />
          </Link>
        </div>
        <div className="form item middle">
          <input
            type="text"
            placeholder={t('products.search')}
            onChange={handleSearchChange}
            className="search-input"
          />
        </div>
        <div className="item">
          <Plus color="#FFF" onClick={handleClickNewProduct} />
        </div>
      </Flex>

      <div className="wrapper">
        <div className="product-list">
          {filteredProducts.map((product) => (
            <ProductCard key={product.id} product={product} onEdit={handleEdit} onDelete={handleOpenModal} />
          ))}
          <div ref={loadMoreRef} className="load-more">
            {loading ? (
              <p>{t('products.loading')}</p>
            ) : hasMore ? (
              <p>{t('products.move')}</p>
            ) : (
              <p>{t('products.nomore')}</p>
            )}
          </div>
        </div>
      </div>

      <ConfirmModal
        t={t}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleDelete}
        message={t('productDeleteConfirm')}
      />
    </div>
  );
};

export default Products;
