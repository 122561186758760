import Flex from '../Flex/Flex';
import Block from '../Block/Block';
import './Loader.scss';
const BRAND = process.env.REACT_APP_TENANT;
export default function Loader() {
  return (
    <>
      <Block />
      <div className="loader">
        <Flex alignCenter column>
          <img src={`${BRAND}/assets/logo.png`} alt="Brain" />
        </Flex>
      </div>
    </>
  );
}
