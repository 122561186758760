import { atom } from 'recoil';

const textState = atom({
  key: 'textState', // Un identificador único para el átomo
  default: '', // Valor predeterminado del estado
});

const toastState = atom({
  key: 'toastState',
  default: {
    text: '',
    time: 1500,
    background: 'var(--color-base)',
  },
});

const productState = atom({
  key: 'productState',
  default: null,
});

export { toastState, textState, productState };
