import { useState } from 'react';
import Button from '../../../../components/Button/Button';
import Flex from '../../../../components/Flex/Flex';
import NumberInput from '../../../../components/NumberInput/NumberInput';
import { formatCurrency } from '../../../../utils/Utils';
import CheckBox from '../../../../components/Checkbox/CheckBox';
import { useTranslation } from 'react-i18next';

export default function useDetails({ products, cooks, handleSetproducts, drinks }) {
  const [showPersonalize, setShowPersonalize] = useState(false);
  const [showAdditionals, setShowAdditionals] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const { t } = useTranslation();

  const handleQty = ({ item, qty }) => {
    const a = [...products];
    a[itemSelected.index].additionals.find((it) => it.id === item.id).quantity = qty;
    handleSetproducts(a);
  };

  const getItemsSelected = () => {
    const prod = products.map((item, index) => getItem({ item: item, index: index }));

    const drink = drinks
      .filter((item) => item.checked)
      .map((item, index) => {
        return getItemDrink({ item: item });
      });

    return (
      <>
        {prod} {drink}
      </>
    );
  };

  const handleSetItemAdditionalForProduct = (id) => {
    const a = [...products];
    const add = a[itemSelected.index].additionals.find((it) => it.id === id);
    add.checked = !add.checked;
    handleSetproducts(a);
  };

  const getAdditionals = () => {
    return products[itemSelected.index].additionals.map((item, index) => {
      return (
        <Flex
          handleClick={() => handleSetItemAdditionalForProduct(item.id)}
          spaceBtw
          className={`item${item.checked ? ' selected' : ''}`}
          key={`_add-${index}`}
        >
          <Flex column>
            <div className="title">{item.Ingredient.name}</div>
            <div className="price">{formatCurrency(item.price)}</div>
          </Flex>
          <Flex>
            <NumberInput
              disabled={!item.checked}
              value={item.quantity}
              onChange={(value) => handleQty({ item: item, qty: value })}
            />
          </Flex>
        </Flex>
      );
    });
  };

  const handleCheckBoxForPersonalize = ({ id }) => {
    const updatedProducts = [...products];
    const { Ingredients } = updatedProducts[itemSelected.index];
    const ingredient = Ingredients.find((item) => item.ProductIngredient.personalize && item.id === id);
    if (ingredient) {
      ingredient.checked = ingredient.hasOwnProperty('checked') ? !ingredient.checked : false;
    }
    handleSetProducts(updatedProducts);
  };

  const getIngredientsForPersonalize = () => {
    return (
      <Flex column>
        <Flex justifyCenter>
          <p>Personalize</p>
        </Flex>

        {products[itemSelected.index].Ingredients.filter((item) => item.ProductIngredient.personalize).map(
          (item, index) => {
            return (
              <Flex spaceBtw className={`item`} key={`_ing-${index}`}>
                <div className="title">{item.name}</div>
                <CheckBox
                  handleCheckBox={(e) => handleCheckBoxForPersonalize({ id: item.id })}
                  checked={item.hasOwnProperty('checked') ? item.checked : item.ProductIngredient.personalize}
                />
              </Flex>
            );
          }
        )}
      </Flex>
    );
  };

  const setCookForProduct = ({ cookId, productId, index }) => {
    const a = [...products];
    a[index].cookTypeId = cookId;
    handleSetproducts(a);
  };

  const getCooks = ({ id, index, selected }) => {
    return (
      <select
        value={selected}
        onChange={(e) => setCookForProduct({ cookId: e.target.value, productId: id, index: index })}
      >
        {cooks.map((item, index) => (
          <option key={`___${index}`} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    );
  };

  const handleAddtionals = ({ id, index }) => {
    setItemSelected({ id: id, index: index });
    setShowAdditionals(!showAdditionals);
  };

  const handleResetItem = () => {
    const a = [...products];
    if (showAdditionals) {
      a[itemSelected.index].additionals.forEach((it) => ((it.quantity = 1), (it.checked = false)));
      setShowAdditionals(false);
    }
    if (showPersonalize) {
      a[itemSelected.index].Ingredients.forEach((it) => (it.checked = true));
      setShowPersonalize(false);
    }

    handleSetproducts(a);
  };

  const getAdditionalsFromEachItem = ({ index }) => {
    if (!itemSelected) return;

    return products[index].additionals
      .filter((it) => it.checked)
      .map((item) => `${item.Ingredient.name} x ${item.quantity}`)
      .join(', ');
  };

  const getPersonalizeFromEachItem = ({ index }) => {
    if (!itemSelected) return;

    return products[index].Ingredients.filter((it) => it.hasOwnProperty('checked') && !it.checked)
      .map((item) => item.name)
      .join(', ');
  };

  const getItem = ({ item, index }) => {
    return (
      <Flex column className="item" key={`_id-${index}_${item.id}`}>
        <Flex alignCenter spaceBtw>
          <Flex column>
            <div className="title">{item.name}</div>
            {getCooks({ id: item.id, index: index, selected: item.cookTypeId })}
          </Flex>
          <Flex column>
            <Button
              handleClick={() => handleAddtionals({ id: item.id, index: index })}
              label={t('additionals')}
              primary
            />
            <Button
              handleClick={() => handleShowPersonalize({ id: item.id, index: index })}
              label={t('personalize')}
              primary
            />
          </Flex>
        </Flex>
        <Flex column>
          <div className="sep" />
          <label>{t('additionals')}</label>
          <Flex>{getAdditionalsFromEachItem({ index: index })}</Flex>
        </Flex>
        <Flex column>
          <div className="sep" />
          <label>{t('exclude')}</label>
          <Flex>{getPersonalizeFromEachItem({ index: index })}</Flex>
        </Flex>
      </Flex>
    );
  };

  const getItemDrink = ({ item, index }) => {
    return (
      <Flex column className="item" key={`_id-${index}_${item.id}`}>
        <Flex alignCenter spaceBtw>
          <div className="title">{item.name}</div>
          <div className="title">x {item.quantity}</div>
        </Flex>
      </Flex>
    );
  };

  const handleSetItem = () => {
    setShowPersonalize(false);
    setShowAdditionals(false);
  };

  const handleShowPersonalize = ({ id, index }) => {
    setItemSelected({ id: id, index: index });
    setShowPersonalize(!showPersonalize);
  };

  return {
    getAdditionals,
    showAdditionals,
    getItemsSelected,
    handleResetItem,
    handleSetItem,
    showPersonalize,
    getIngredientsForPersonalize,
    t,
  };
}
