export const en = {
  login: {
    signIn: 'Sign in',
    signUp: 'Sign up',
    speech: 'Welcome to Brain, the educational revolution powered by artificial intelligence.',
    email: 'E-mail',
    password: 'Password',
    or: 'or',
    here: 'here',
  },
  a: 'a',
};
