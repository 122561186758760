const productsCache = new Map();
const cacheTTL = 300000;
const cacheTimestamps = new Map();
const MAX_CACHE_SIZE = 100;

export const getCachedProducts = (query) => {
  const timestamp = cacheTimestamps.get(query);
  if (timestamp && Date.now() - timestamp < cacheTTL) {
    return productsCache.get(query);
  }
  // Si han caducado, elimina la entrada de caché
  productsCache.delete(query);
  cacheTimestamps.delete(query);
  return null; // No hay productos en caché
};

export const cacheProducts = (query, products) => {
  if (productsCache.size >= MAX_CACHE_SIZE) {
    const oldestKey = productsCache.keys().next().value;
    productsCache.delete(oldestKey);
    cacheTimestamps.delete(oldestKey);
  }
  productsCache.set(query, products);
  cacheTimestamps.set(query, Date.now());
};

export const invalidateCacheForProduct = (productId) => {
  productsCache.forEach((_, key) => {
    if (key.includes(productId)) {
      productsCache.delete(key);
      cacheTimestamps.delete(key);
    }
  });
};
