import axios from './Client';

const google = async ({ token }) => {
  try {
    const response = await axios.post(`/users/authz-google`, {
      token: token,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const login = async (user) => {
  try {
    const response = await axios.post(`/users/login`, user);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const logout = async (data) => {
  const response = await axios.post(`/users/logout`, data);
  return response.data;
};

const validate = async () => {
  try {
    const response = await axios.get(`/users/validate`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const whoami = async () => {
  try {
    const response = await axios.get(`/users/whoami`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const register = async (user) => {
  const response = await axios.post(`/users/register`, user);
  return response.data;
};

const changePassword = async (data) => {
  const response = await axios.patch(`/users/password`, data);
  return response.data;
};

const removeUser = async () => {
  const response = await axios.patch(`/users/remove`);
  return response.data;
};

export default { google, login, logout, validate, register, whoami, changePassword, removeUser };
