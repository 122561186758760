import { Route, Routes, HashRouter } from 'react-router-dom';

import Login from './pages/Login/Login';
import Toast from './components/Toast/Toast';
import PrivateRoutes from './utils/PrivateRoutes';
import Flex from './components/Flex/Flex';
import Logo from './assets/logo.png';
import AuthProvider from './hooks/AuthProvider';

import { RemoveTrailingSlash } from './utils/RemoveTrailingSlash';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import Home from './pages/Home/Home';
import Orders from './pages/Orders/Orders';
import { useEffect } from 'react';
import { useTenantMeta } from './hooks/useTenantMeta';
import Search from './pages/Search/Search';
import Daily from './pages/Reports/Daily';
import Product from './pages/Products/components/Product';
import Products from './pages/Products/Products';
import Sales from './pages/Sales/Sales';
import Donate from './pages/Donate/Donate';
import About from './pages/About/About';
import Feedback from './pages/Feedback/Feedback';
import Profile from './pages/Profile/Profile';
const tenant = process.env.REACT_APP_TENANT;

export default function App() {
  useTenantMeta(tenant);

  useEffect(() => {
    const appleLinkIcon = document.querySelector("link[rel='apple-touch-icon']");
    if (appleLinkIcon) {
      appleLinkIcon.href = appleLinkIcon.href.replace('tenant', tenant);
    }
    const links = document.querySelectorAll("link[rel='icon']");
    links.forEach((link) => {
      link.href = link.href.replace('tenant', tenant);
    });
  }, []);

  const NotFound = () => {
    return (
      <Flex column alignCenter className="not-found">
        <img src={Logo} alt="brain" width={140} />
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for could not be found.</p>
      </Flex>
    );
  };
  return (
    <I18nextProvider i18n={i18n}>
      <HashRouter>
        <RemoveTrailingSlash />
        <Toast />
        <AuthProvider>
          <Routes>
            <Route exact path="/auth/login" element={<Login />} />
            <Route element={<PrivateRoutes />}>
              <Route path="/" element={<Home />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/search" element={<Search />} />
              <Route path="/daily" element={<Daily />} />
              <Route path="/products" element={<Products />} />
              <Route path="/products/product/:id?" element={<Product />} />
              <Route path="/sales" element={<Sales />} />
              <Route path="/donate" element={<Donate />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/about" element={<About />} />
              <Route path="/feedback" element={<Feedback />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </HashRouter>
    </I18nextProvider>
  );
}
