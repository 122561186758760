import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { cacheProducts, getCachedProducts } from '../../utils/productsCache';
import ProductsService from '../../services/Products.service';
import { useTranslation } from 'react-i18next';
import useToast from '../../hooks/useToast';
import { useAuth } from '../../hooks/AuthProvider';
import OrderService from '../../services/Order.service';
import confetti from 'canvas-confetti';
import CookTypesService from '../../services/CookTypes.service';
import PaymentsService from '../../services/Payments.service';

export default function useSales() {
  const [query, setQuery] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [quantity, setQuantity] = useState('');
  const [products, setProducts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [cook, setCook] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { updateToast } = useToast();
  const { user } = useAuth();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const [paymentRes, cookRes] = await Promise.all([PaymentsService.get(), CookTypesService.get()]);
        setPayments(paymentRes);
        setCook(cookRes);
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  const sanitizeInput = (input) => {
    return input.replace(/[^a-zA-Z0-9\s]/g, ''); // Permite solo letras, números y espacios
  };

  const searchProducts = async (searchQuery) => {
    if (searchQuery.length >= 3) {
      setLoading(true);
      try {
        const cachedProducts = getCachedProducts(searchQuery);
        if (cachedProducts) {
          setProducts(cachedProducts);
          return;
        }

        const response = await ProductsService.getByQuery({ query: searchQuery });

        setProducts(response);
        cacheProducts(searchQuery, response);
      } catch (error) {
        console.error('Error al buscar productos:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setProducts([]); // Limpiar resultados si hay menos de 3 letras
    }
  };

  // Usamos useCallback para memoizar la función y evitar que se redefina en cada render
  const debouncedSearch = useCallback(
    debounce((value) => searchProducts(value), 500),
    []
  );

  // Maneja el input de búsqueda
  const handleInputChange = (e) => {
    const value = sanitizeInput(e.target.value);
    setQuery(value);
    debouncedSearch(value); // Llamar a la función debounced
  };

  // Función para seleccionar un producto
  const selectProduct = (product) => {
    if (!selectedProducts.some((p) => p.id === product.id)) {
      setSelectedProducts([...selectedProducts, { ...product, quantity }]);
      setProducts([]);
      setQuery('');
    } else {
      updateToast({ text: t('selectedProduct'), type: 'warn' });
    }
  };

  const debouncedCalculateTotal = debounce((products) => {
    calculateTotal(products);
  }, 500);

  const handleQuantityChange = (e, productId) => {
    let newQuantity = e.target.value ? parseFloat(e.target?.value.trim()) : '';

    const last = [...selectedProducts];

    const productToUpdate = last.find((p) => p.id === productId);

    if (productToUpdate) {
      productToUpdate.quantity = newQuantity;
    }

    setSelectedProducts(last);
    !isNaN(newQuantity) && debouncedCalculateTotal(last);
  };

  const calculateTotal = (last) => {
    const b = last.map((product) => product.quantity * product.price);
    const grandTotal = b.reduce((acumulador, actual) => acumulador + actual, 0);
    setTotal(grandTotal);
  };

  const handleDeleteProduct = (productId) => {
    const updatedProducts = selectedProducts.filter((product) => product.id !== productId);
    setSelectedProducts(updatedProducts);
    calculateTotal(updatedProducts);
  };

  const handleReset = () => {
    setSelectedProducts([]);
    setTotal(0);
  };

  const handleSaveSale = async () => {
    const productEmpty = selectedProducts.filter((item) => item.quantity === '');

    if (productEmpty.length > 0) {
      updateToast({ text: t('noItem'), time: 3000 });
      return;
    }

    const customerOrder = {
      order: {
        UserId: user.id,
        total: total,
        PaymentMethodId: payments[0].id,
      },
      products: selectedProducts
        .filter((item) => !isNaN(item.quantity))
        .map(({ id, price, quantity }) => ({
          ProductId: id,
          CookTypeId: cook[0].id,
          price,
          quantity,
          additionals: [],
        })),
      drinks: [],
    };

    try {
      const result = await OrderService.post({ data: customerOrder });
      if (result) {
        updateToast({ text: t('saleCreated') });
        confetti();
      }
      handleReset();
      if (result) {
        updateToast({ text: t('orderCreated') });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    t,
    query,
    loading,
    products,
    total,
    selectProduct,
    selectedProducts,
    handleInputChange,
    handleQuantityChange,
    handleDeleteProduct,
    handleSaveSale,
    handleReset,
  };
}
