import Flex from '../../../../components/Flex/Flex';
import useDetails from './useDetails';
import './Details.scss';
import Button from '../../../../components/Button/Button';

export default function Details({ products, cooks, handleSetproducts, drinks }) {
  const {
    getAdditionals,
    showAdditionals,
    getItemsSelected,
    handleResetItem,
    handleSetItem,
    showPersonalize,
    getIngredientsForPersonalize,
    t,
  } = useDetails({
    products,
    cooks,
    handleSetproducts,
    drinks,
  });

  return (
    <Flex column className="details">
      {!showPersonalize && !showAdditionals && getItemsSelected()}
      {(showAdditionals || showPersonalize) && (
        <>
          {showAdditionals ? getAdditionals() : getIngredientsForPersonalize()}
          <Flex className="toolbar" alignCenter justifyCenter gap={'50px'}>
            <Button handleClick={handleResetItem} label={t('cancel')} primary />
            <Button handleClick={handleSetItem} label={t('ok')} primary selected />
          </Flex>
        </>
      )}
    </Flex>
  );
}
