import { useContext, createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Auth from '../services/Authorization';
import Loader from '../components/Loader/Loader';
import useToast from './useToast';
const AuthContext = createContext();
const TENANT = process.env.REACT_APP_TENANT || TENANT;

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem(TENANT) || '');
  const { updateToast } = useToast();

  const navigate = useNavigate();

  useEffect(() => {
    isAlive({ reset: true });
  }, []);

  const isAlive = async ({ reset = false }) => {
    try {
      if (token) {
        whoami();
      } else {
        redirectToLogin();
      }
    } catch (err) {
      redirectToLogin();
    }
  };

  const setAndGo = ({ data }) => {
    setUser({ ...data.user, refreshToken: data.refreshToken });
    setToken(data.token);
    localStorage.setItem(TENANT, JSON.stringify({ token: data.token, refresh: data.refreshToken }));
    navigate('/');
  };

  const loginAction = async ({ email, password }) => {
    try {
      const data = await Auth.login({ email: email, password: password });
      setAndGo({ data: data });
    } catch (err) {
      throw err;
    }
  };

  const loginGoogleAction = async ({ token }) => {
    try {
      const data = await Auth.google({ token: token });
      setAndGo({ data: data });
    } catch (err) {
      updateToast({ text: err.response.data.message });
    }
  };

  const registerUser = async (data) => {
    try {
      const res = await Auth.register({ data: data });
      setAndGo({ data: res });
    } catch (err) {
      updateToast({ text: err.response.data.message });
    }
  };

  const validateToken = async () => {
    try {
      if (token) await Auth.validate();
    } catch (err) {
      redirectToLogin();
      //updateToast({ text: err.response.data.message });
    }
  };

  const whoami = async () => {
    try {
      const data = await Auth.whoami();
      setUser(data);
    } catch (err) {
      throw err;
    }
  };

  const changePassword = async (data) => {
    try {
      const result = await Auth.changePassword(data);
      return result;
    } catch (err) {
      throw err;
    }
  };

  const removeUser = async () => {
    try {
      const result = await Auth.removeUser();
      if (result) logOut();
    } catch (err) {
      throw err;
    }
  };

  const logOut = async () => {
    try {
      await Auth.logout({ refreshToken: user.refreshToken });
      redirectToLogin();
    } catch (err) {
      // console.error(err);
      navigate('/auth/login');
    }
  };

  const redirectToLogin = async () => {
    setUser(null);
    setToken('');
    localStorage.removeItem(TENANT);
    navigate('/auth/login');
  };

  if (!user && token) return <Loader />;

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        loginAction,
        loginGoogleAction,
        validateToken,
        logOut,
        registerUser,
        isAlive,
        whoami,
        setUser,
        changePassword,
        removeUser,
        redirectToLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
