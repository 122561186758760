import React from 'react';
import { Crown, Salad, ChartPie, Barcode, Heart, LogOut, Sticker, QrCode, Earth, DollarSign } from 'lucide-react';
import './Pill.scss';
import Flex from '../../../components/Flex/Flex';
import classNames from 'classnames';

const PillButton = ({ imageSrc, text, full, Goto, handleClick, disabled = false }) => {
  const cName = classNames('pill-button', {
    disabled,
  });
  return (
    <Flex
      handleClick={() => handleClick({ Goto: Goto, disabled: disabled })}
      alignCenter
      justifyCenter
      column
      className={cName}
    >
      {imageSrc && imageSrc}
      <p className="pill-text">{text}</p>
    </Flex>
  );
};

const Pill = ({ t, handleClick }) => {
  const buttons = [
    /* { imageSrc: <NotebookPen size={48} />, text: t('orders'), Goto: '/orders' },
    { imageSrc: <Search size={48} />, text: t('find'), Goto: '/search' },*/

    { imageSrc: <Barcode size={48} />, text: t('products.text'), Goto: '/products' },
    { imageSrc: <Salad size={48} />, text: t('ingredients'), Goto: '/ingredients', disabled: true },
    { imageSrc: <DollarSign size={48} />, text: t('sales'), Goto: '/sales' },
    { imageSrc: <Crown size={48} />, text: t('report'), Goto: '/daily' },
    { imageSrc: <ChartPie size={48} />, text: t('statistics'), Goto: '/statistics', disabled: true },
    { imageSrc: <Heart fill="red" size={48} color="red" />, text: t('donate'), Goto: '/donate' },
    { imageSrc: <Sticker size={48} />, text: t('feed'), Goto: '/feedback' },
    { imageSrc: <QrCode size={48} />, text: t('qr'), Goto: '/qr', disabled: true },
    { imageSrc: <Earth size={48} />, text: t('mySpace'), Goto: '/space', disabled: true },
  ];

  return (
    <div className="pill-button-container">
      {buttons.map((button, index) => (
        <PillButton
          key={index}
          imageSrc={button.imageSrc}
          text={button.text}
          full={button.full}
          Goto={button.Goto}
          handleClick={handleClick}
          disabled={button.disabled || false}
        />
      ))}
    </div>
  );
};

export default Pill;
