import React from 'react';
import Flex from '../Flex/Flex';
import Button from '../Button/Button';
import './Number.scss';

export default function NumberInput({ value = 1, onChange, disabled }) {
  const handleIncrement = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(value + 1);
  };

  const handleDecrement = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (value > 1) {
      onChange(value - 1);
    }
  };

  return (
    <Flex disabled={disabled} gap={'1px'} alignCenter className={`number-input --${disabled}`}>
      <Button handleClick={handleDecrement} iconName={'Minus'} />
      <div className="value">{value}</div>
      <Button handleClick={handleIncrement} iconName={'Plus'} />
    </Flex>
  );
}
