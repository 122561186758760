import axios from './Client';

const get = async () => {
  const response = await axios.get(`/drinks`);
  return response.data;
};

const put = async ({ id, data }) => {
  const response = await axios.put(`/drinks/${id}`, data);
  return response.data;
};

const del = async ({ id }) => {
  const response = await axios.delete(`/drinks/${id}`);
  return response.data;
};

const post = async ({ data }) => {
  const response = await axios.post(`/drinks`, data);
  return response.data;
};

export default { get, put, post, del };
