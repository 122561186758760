import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { es } from './es';
import { en } from './en';
import { pt } from './pt';

i18next.use(initReactI18next).init({
  lng: 'es',
  fallbackLng: 'es',
  resources: {
    es: {
      translation: es,
    },
    en: {
      translation: en,
    },
    pt: {
      translation: pt,
    },
  },
  returnObjects: false,
  keySeparator: '.',
  returnedObjectHandler: (key, value, options) => value,
});

export default i18next;
