import { useState } from 'react';
import './CheckBox.scss';
export default function CheckBox({ checked, handleCheckBox, disabled }) {
  // const [checked, setChecked] = useState(isChecked);
  return (
    <label className={`switch${disabled ? ' disabled' : ''}`}>
      <input checked={checked} onChange={handleCheckBox} type="checkbox" />
      <span className="slider"></span>
    </label>
  );
}
