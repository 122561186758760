import { useState, useEffect, useCallback, useRef } from 'react';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import ProductsService from '../../services/Products.service';
import { useSetRecoilState } from 'recoil';
import { productState } from '../../utils/states';
import { encryptData } from '../../utils/Utils';
import useToast from '../../hooks/useToast';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/AuthProvider';

export default function useProducts() {
  const navigate = useNavigate();
  const { redirectToLogin } = useAuth();
  const [products, setProducts] = useState([]);
  const { updateToast } = useToast();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const observer = useRef();
  const loadMoreRef = useRef();
  const setProductState = useSetRecoilState(productState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const { t } = useTranslation();

  const fetchProducts = async (pageNumber) => {
    const newProducts = Array.from({ length: 10 }, (_, i) => ({
      id: i + (pageNumber - 1) * 10,
      name: `Producto ${i + 1 + (pageNumber - 1) * 10}`,
      price: (Math.random() * 100).toFixed(2),
      quantity: Math.floor(Math.random() * 1000),
    }));
    return newProducts;
  };

  const loadMoreProducts = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const newProducts = await ProductsService.getByPage({ page: page });

      if (newProducts.length > 0) {
        setProducts((prevProducts) => [...prevProducts, ...newProducts]);
        setPage((prevPage) => prevPage + 1);
      } else {
        setHasMore(false);
      }

      setLoading(false);
    } catch (error) {}
  }, [loading, page, hasMore]);

  // Filtrar productos basados en el término de búsqueda
  useEffect(() => {
    const results = products.filter((product) => product.name.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredProducts(results);
  }, [searchTerm, products]);

  // Función debounce para actualizar el término de búsqueda
  const debounceSearch = useRef(
    debounce((term) => {
      setSearchTerm(term);
    }, 300) // Tiempo de debounce en milisegundos
  ).current;

  const handleSearchChange = (e) => {
    debounceSearch(e.target.value);
  };

  useEffect(() => {
    // loadMoreProducts();
    localStorage.removeItem('product');
  }, []);

  useEffect(() => {
    if (loading) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        loadMoreProducts();
      }
    });

    if (loadMoreRef.current) {
      observer.current.observe(loadMoreRef.current);
    }
  }, [loading, hasMore, loadMoreProducts]);

  const handleEdit = (product) => {
    setProductState(product);
    const encryptedProduct = encryptData(product);
    localStorage.setItem('product', encryptedProduct);
    navigate(`/products/product/${product.id}`);
  };

  const handleDelete = async () => {
    handleCloseModal();
    const filteredProducts = products.filter((p) => p.id !== productToDelete.id);
    setProducts(filteredProducts);
    try {
      const response = await ProductsService.del({ id: productToDelete.id });
      updateToast({ text: t('products.deleted') });
    } catch (error) {
      updateToast({ text: error.message });
    }
  };

  const handleClickNewProduct = () => {
    navigate('/products/product');
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = (product) => {
    setProductToDelete(product);
    setIsModalOpen(true);
  };

  return {
    t,
    loadMoreRef,
    isModalOpen,
    productToDelete,
    loading,
    hasMore,
    filteredProducts,
    handleClickNewProduct,
    handleDelete,
    handleCloseModal,
    handleEdit,
    handleSearchChange,
    handleOpenModal,
  };
}
