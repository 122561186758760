// ConfirmModal.js
import React from 'react';
import './ConfirmModal.scss'; // Asegúrate de crear un archivo CSS para el estilo
import Button from '../Button/Button';

const ConfirmModal = ({ isOpen, onClose, onConfirm, message, t }) => {
  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Confirmar</h2>
        <p>{message}</p>
        <div className="modal-actions">
          <Button gray handleClick={onClose} label={t('cancel')} />
          <Button primary handleClick={onConfirm} label={t('delete')} />
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
