import { useState } from 'react';
import useToast from '../../../hooks/useToast';

export default function useDonation({ onSubmit, t }) {
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [customAmount, setCustomAmount] = useState('');
  const [isMonthly, setIsMonthly] = useState(false);
  const { updateToast } = useToast();

  const handleAmountClick = (index) => {
    setSelectedAmount(index);
    setCustomAmount('');
  };

  const handleCustomAmountChange = (e) => {
    setSelectedAmount(null);
    setCustomAmount(e.target.value);
  };

  const handleByMonth = (e) => {
    setIsMonthly(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const amount = selectedAmount || customAmount;
    const donationType = isMonthly ? 'Mensual' : 'Ùnica';

    if (!amount || isNaN(amount)) {
      updateToast({ text: t('donation.warn') });
      return;
    }

    onSubmit({
      amount: customAmount ? customAmount : t('donation.values')[selectedAmount],
      donationType: donationType,
      type: 'Donation',
    });
  };

  return {
    selectedAmount,
    customAmount,
    isMonthly,
    handleAmountClick,
    handleCustomAmountChange,
    handleByMonth,
    handleSubmit,
  };
}
