import React from 'react';
import classNames from 'classnames';
import './Flex.scss';

export default function Flex({
  children,
  gap = '6px',
  alignCenter = false,
  justifyCenter = false,
  justifyEnd = false,
  column = false,
  spaceBtw = false,
  className = '',
  handleClick,
  disabled = false,
  hide = false,
}) {
  const cName = classNames('flex', className, {
    alignCenter,
    column,
    spaceBtw,
    justifyCenter,
    justifyEnd,
    disabled,
    hide,
  });

  return (
    <div onClick={handleClick} style={{ gap }} className={cName}>
      {children}
    </div>
  );
}
