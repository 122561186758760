import Flex from '../../../../components/Flex/Flex';
import NumberInput from '../../../../components/NumberInput/NumberInput';
import { formatCurrency } from '../../../../utils/Utils';
import './Cards.scss';

export default function Cards({ t, data, handleSelect, handleQty }) {
  return (
    <Flex className="cards" column>
      {data.map((item, index) => {
        return (
          <Flex
            handleClick={() => handleSelect(item)}
            key={`in_${index}`}
            spaceBtw
            alignCenter
            className={`card${item.checked ? ' selected' : ''}`}
          >
            <Flex column>
              <div className="title">{item.name}</div>
              <div className="price">{formatCurrency(item.price, t('currency'))}</div>
            </Flex>
            <Flex>
              <NumberInput
                disabled={!item.checked}
                value={item.quantity}
                onChange={(value) => handleQty({ item: item, qty: value })}
              />
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
}
