import React from 'react';
import './Profile.scss';
import useProfile from './useProfile';
import Flex from '../../components/Flex/Flex';
import { Link } from 'react-router-dom';
import { House, LogOut } from 'lucide-react';
import Button from '../../components/Button/Button';
import CheckBox from '../../components/Checkbox/CheckBox';

const Profile = () => {
  const {
    t,
    user,
    newPassword,
    oldPassword,
    repeatNewPassword,
    decision,
    handleDeleteAccount,
    handlePasswordChange,
    handleOldPasswordChange,
    handleSubmit,
    handleLogout,
    handleDecision,
    handleRepeatPasswordChange,
  } = useProfile();

  return (
    <div className="profile">
      <Flex alignCenter className="header">
        <Link to="/">
          <House color="#FFF" />
        </Link>
      </Flex>
      <div className="wrapper">
        <Flex column className="wrapper__data">
          <Flex spaceBtw alignCenter>
            <h2 className="wrapper__data__title">{t('profile.text')}</h2>
            <LogOut onClick={handleLogout} />
          </Flex>

          <div className="wrapper__data__info">
            <p className="wrapper__data__info-p">
              {t('hi')} {user.name} {user.surname}!
            </p>
            <small>{user.email}</small>
          </div>

          <form onSubmit={handleSubmit} className="wrapper__data__change-password-form">
            <label htmlFor="new-password" className="data__label">
              {t('profile.changePassword')}
            </label>
            <p className="data__password-info">{t('profile.info')}</p>

            <input
              type="password"
              className="data__input"
              value={oldPassword}
              onChange={handleOldPasswordChange}
              placeholder={t('profile.oldPassword')}
              autoComplete="current-password"
              required
            />
            <input
              type="password"
              className="data__input"
              value={newPassword}
              onChange={handlePasswordChange}
              placeholder={t('profile.newPassword')}
              autoComplete="new-password"
              required
            />
            <input
              type="password"
              className="data__input"
              value={repeatNewPassword}
              onChange={handleRepeatPasswordChange}
              placeholder={t('profile.repeatNewPassword')}
              autoComplete="new-password"
              required
            />
            <Button full primary type="submit" label={t('profile.changePassword')} />
          </form>

          <Flex column className="data__delete-account-info">
            <label htmlFor="new-password" className="data__label">
              {t('profile.unsuscribe')}
            </label>
            <p className="data__password-info">{t('profile.unsuscribeInfo')}</p>
            <Flex alignCenter>
              <p className="data__password-info">{t('profile.decision')}</p>
              <CheckBox handleCheckBox={handleDecision} checked={decision} />
            </Flex>
          </Flex>

          <Button
            className="data__delete-button"
            disable={!decision}
            primary
            handleClick={handleDeleteAccount}
            label={t('profile.unsuscribe')}
          />
        </Flex>
      </div>
    </div>
  );
};

export default Profile;
