import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { toastState } from '../../utils/states';

const useToast = () => {
  const { text, time, background } = useRecoilValue(toastState);
  const setToastState = useSetRecoilState(toastState);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (text) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
        setTimeout(() => {
          setToastState((prev) => ({ ...prev, text: '' }));
        }, 500);
      }, time);

      return () => clearTimeout(timer);
    }
  }, [text, time, setToastState]);

  if (!text) return null;
  return (
    <div className={`toast ${isVisible ? 'show' : ''}`} style={{ background }}>
      {text}
    </div>
  );
};

export default useToast;
