import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/AuthProvider';
import { useTranslation } from 'react-i18next';
import useToast from '../../hooks/useToast';

export default function useProfile() {
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [decision, setDecision] = useState(false);
  const { user, logOut, changePassword, removeUser, validateToken } = useAuth();
  const { t } = useTranslation();
  const { updateToast } = useToast();

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+{}":;'\[\]\\|,.<>\/?~`-]/.test(password);

    return minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePassword(newPassword)) {
      updateToast({ text: t('profile.remember'), time: 6000 });
      return;
    }

    if (newPassword !== repeatNewPassword) {
      updateToast({ text: t('profile.noMatch'), time: 2500 });
      return;
    }

    try {
      await changePassword({ oldPassword: oldPassword, newPassword: newPassword });
      updateToast({ text: t('profile.passwordChanged') });
      setOldPassword('');
      setNewPassword('');
      setRepeatNewPassword('');
    } catch (error) {
      updateToast({ text: t(`profile.${error.response.data.code}`) });
    }
  };

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    removeUser();
  };

  const handleLogout = (e) => {
    logOut();
  };

  const handleDecision = (e) => {
    setDecision(e.target.checked);
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };
  const handleRepeatPasswordChange = (e) => {
    setRepeatNewPassword(e.target.value);
  };

  return {
    t,
    user,
    newPassword,
    oldPassword,
    repeatNewPassword,
    decision,
    handleDeleteAccount,
    handlePasswordChange,
    handleOldPasswordChange,
    handleSubmit,
    handleLogout,
    handleDecision,
    handleRepeatPasswordChange,
  };
}
