import { useEffect, useRef, useState } from 'react';
import UnitService from '../../../services/Unit.service';
import { useTranslation } from 'react-i18next';
import ProductsService from '../../../services/Products.service';
import useToast from '../../../hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { productState } from '../../../utils/states';
import { decryptData } from '../../../utils/Utils';

export default function useProduct() {
  const [units, setUnits] = useState(null);
  const { updateToast } = useToast();
  const { id } = useParams();
  const formRef = useRef(null);
  const [ingredients, setIngredients] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const productEdit = useRecoilValue(productState);
  const [product, setProduct] = useState({
    name: '',
    description: '',
    extras: '',
    price: '',
    UnitId: '',
  });

  useEffect(() => {
    const storedProduct = localStorage.getItem('product');

    if (!productEdit && storedProduct) {
      const productData = decryptData(storedProduct);
      loadProductInForm(productData);
    } else if (!productEdit && id) {
      fetchProductFromAPI();
    } else if (id) {
      loadProductInForm(productEdit);
    }
  }, [productEdit]);

  const fetchProductFromAPI = async () => {
    try {
      const response = await ProductsService.getById({ id: id });
      loadProductInForm(response);
    } catch (error) {
      updateToast({ text: error.message });
    }
  };

  const loadProductInForm = (product) => {
    setProduct({
      name: product.name,
      description: product.description,
      extras: product.extras,
      price: product.price,
      UnitId: product.Unit.id,
    });
  };

  const ingredientsList = [
    { id: '1', name: 'Harina' },
    { id: '2', name: 'Azúcar' },
    { id: '3', name: 'Sal' },
    { id: '4', name: 'Hielo' },
    { id: '5', name: 'Otro' },
    // Agrega más ingredientes según tu base de datos
  ];

  const getAvailableIngredients = (index) => {
    return ingredientsList
      .filter(
        (ing) =>
          !ingredients.some(
            (selectedIng, selectedIndex) => selectedIng.ingredient?.value === ing.id && selectedIndex !== index
          )
      )
      .map((ing) => ({ value: ing.id, label: ing.name }));
  };

  const handleIngredientChange = (index, selectedOption) => {
    const updatedIngredients = ingredients.map((ing, ingIndex) =>
      ingIndex === index ? { ...ing, ingredient: selectedOption } : ing
    );
    setIngredients(updatedIngredients);
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedIngredients = ingredients.map((ing, ingIndex) => (ingIndex === index ? { ...ing, quantity } : ing));
    setIngredients(updatedIngredients);
  };

  const addIngredientField = () => {
    updateToast({ text: t('feedbackSection.only') });

    return;

    // Solo agrega un campo nuevo si el último campo está completo
    const lastIngredient = ingredients[ingredients.length - 1];
    if (!lastIngredient || (lastIngredient.ingredient && lastIngredient.quantity)) {
      setIngredients([...ingredients, { ingredient: null, quantity: '' }]);
    } else {
      alert('Completa el ingrediente y la cantidad antes de agregar otro.');
    }
  };

  const removeIngredientField = (index) => {
    const updatedIngredients = ingredients.filter((_, ingIndex) => ingIndex !== index);
    setIngredients(updatedIngredients);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevenir que se envíe el formulario
      e.target.blur(); // Desenfocar el input para ocultar el teclado virtual
    }
  };

  const handleUnitChange = (e) => {
    setProduct((prevState) => ({
      ...prevState,
      UnitId: e.target.value,
    }));
  };

  const handleInputProduct = (e) => {
    setProduct((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validIngredients = ingredients.filter((ing) => ing.ingredient && ing.quantity);

    const prepareData = {
      ...product,
      receipe: '',
      ingredients: validIngredients.map((ing) => ({
        ingredientId: ing.ingredient.value,
        quantity: parseFloat(ing.quantity),
      })),
    };

    try {
      const result = (await id)
        ? ProductsService.patch({
            id: id,
            data: prepareData,
          })
        : ProductsService.post({
            data: prepareData,
          });
      if (result) updateToast({ text: id ? t('products.updated') : t('products.created') });
      if (id) navigate('/products');
    } catch (error) {
      updateToast({ text: error.message });
    }

    // Resetear el formulario
    setProduct({
      name: '',
      description: '',
      cost: '',
      price: '',
    });
    setIngredients([]);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await UnitService.get();
        setUnits(response);
      } catch (error) {}
    };
    fetch();
  }, []);

  useEffect(() => {
    const handleFocus = (e) => {
      const target = e.target;
      if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
        formRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    window.addEventListener('focusin', handleFocus);

    return () => {
      window.removeEventListener('focusin', handleFocus);
    };
  }, []);

  return {
    t,
    formRef,
    units,
    product,
    ingredients,
    getAvailableIngredients,
    removeIngredientField,
    addIngredientField,
    handleSubmit,
    handleIngredientChange,
    handleKeyDown,
    handleQuantityChange,
    handleUnitChange,
    handleInputProduct,
  };
}
