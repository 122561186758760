import Button from '../../../../components/Button/Button';
import Flex from '../../../../components/Flex/Flex';
import Cards from '../Cards/Cards';

export default function Step1({
  data,
  handleFilterSelect,
  filterSelect,
  handleQty,
  handleSelect,
  handleReset,
  handleStep,
  continueEnabled,
  t,
}) {
  return (
    <>
      <Flex spaceBtw className="btn-head">
        <Button
          handleClick={() => handleFilterSelect(0)}
          selected={!filterSelect}
          iconName={'Barcode'}
          iconColor="white"
          label={t('products')}
          primary
        />
        <Button
          handleClick={() => handleFilterSelect(1)}
          selected={filterSelect === 1}
          iconName={'UtensilsCrossed'}
          iconColor="white"
          label={t('combos')}
          primary
        />
        <Button
          handleClick={() => handleFilterSelect(2)}
          selected={filterSelect === 2}
          iconName={'CupSoda'}
          iconColor="white"
          label={t('drinks')}
          primary
        />
      </Flex>
      <div className="content">
        <Cards t={t} handleQty={handleQty} data={data} handleSelect={handleSelect} />
      </div>
      <Flex gap={'50px'} alignCenter justifyCenter className="footer">
        <Button
          disable={!data.filter((item) => item.checked).length}
          handleClick={handleReset}
          iconName={'RouteOff'}
          iconColor="white"
          label={t('cancel')}
          primary
        />
        <Button
          handleClick={() => handleStep(1)}
          disable={!continueEnabled()}
          selected={continueEnabled()}
          iconName={'ArrowRight'}
          iconColor="white"
          label={t('next')}
          primary
        />
      </Flex>
    </>
  );
}
