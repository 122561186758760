import React from 'react';
import './OrderDetails.scss'; // Importa los estilos CSS

const OrderDetails = ({ t, order }) => {
  return (
    <div className="table-container">
      <h2>{t('order.title')}</h2>
      <table>
        <tbody>
          <tr className="header">
            <td colSpan="2">{t('order.resume')}</td>
          </tr>
          <tr>
            <th>{t('order.date')}:</th>
            <td>{new Date(order.createdAt).toLocaleDateString()}</td>
          </tr>
          <tr>
            <th>{t('order.total')}:</th>
            <td>${order.total}</td>
          </tr>
          <tr>
            <th>{t('order.method')}:</th>
            <td>{order.PaymentMethod?.name}</td>
          </tr>
          <tr>
            <th>{t('order.name')}:</th>
            <td>
              {order.User?.name} {order.User?.surname}
            </td>
          </tr>
        </tbody>
      </table>

      <h2>{t('products')}</h2>
      {order.Products.map((product) =>
        product.OrderProducts.map((item, index) => (
          <table key={index} className="table-container">
            <tbody>
              <tr className="header">
                <td colSpan="2">{product.name}</td>
              </tr>
              <tr>
                <th>{t('quantity')}:</th>
                <td>{item.quantity}</td>
              </tr>
              <tr>
                <th>{t('order.cook')}:</th>
                <td>{item.CookType.name}</td>
              </tr>
              {item.Additionals?.length > 0 && (
                <tr>
                  <th>{t('order.additionals')}:</th>
                  <td>
                    <ul>
                      {item.Additionals.map((additional, idx) => (
                        <li key={idx}>{additional.Ingredient?.name}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ))
      )}

      {order.Drinks.length > 0 && (
        <>
          <table className="table-container">
            <tbody>
              <tr className="header">
                <td colSpan="2">{t('order.drinks')}</td>
              </tr>
              {order.Drinks.map((drink, index) => (
                <tr key={index}>
                  <th colSpan="2">{drink.name}</th>
                  <td>{drink.OrderDrink.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default OrderDetails;
