import Button from '../../../../components/Button/Button';
import Flex from '../../../../components/Flex/Flex';
import { formatCurrency } from '../../../../utils/Utils';
import Details from '../Details/Details';

export default function Step2({ getTotalSale, handleSetproducts, cookTypes, drinks, products, handleStep, t }) {
  return (
    <>
      <Flex column className="form">
        <Flex justifyCenter className="total">
          {`Total: ${formatCurrency(getTotalSale(), t('currency'))}`}
        </Flex>
      </Flex>
      <div className="content">
        <Details handleSetproducts={handleSetproducts} cooks={cookTypes} drinks={drinks} products={products} />
      </div>
      <Flex gap={'50px'} alignCenter justifyCenter className="footer">
        <Button handleClick={() => handleStep(-1)} iconName={'ArrowLeft'} iconColor="white" label={t('back')} primary />
        <Button
          handleClick={() => handleStep(1)}
          selected
          iconName={'DollarSign'}
          iconColor="white"
          label={t('end')}
          primary
        />
      </Flex>
    </>
  );
}
